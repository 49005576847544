import { captureException } from "@sentry/nextjs";
import { DateTime } from "luxon";

type Dateish = Date | DateTime | string;

export const OPERATING_TIMEZONE = "America/New_York";

/**
 * The number of calendar days between two dates, in
 * OPERATING_TIMEZONE
 */
export function daysBetween(past: Dateish, future: Dateish) {
  const pastDT = coerceDateTime(past)
    .setZone(OPERATING_TIMEZONE)
    .startOf("day");

  const futureDT = coerceDateTime(future)
    .setZone(OPERATING_TIMEZONE)
    .startOf("day");

  return Math.floor(futureDT.diff(pastDT, ["days"]).days);
}

export function dateTimeFormal(date: Date | DateTime | string): string {
  const dateTime = coerceDateTime(date);

  return toNormalSpaces(dateTime.toFormat("DDDD, 'at' t ZZZZ"));
}

export function dateHugeFormat(date: Dateish, isWallOrTz?: boolean | string) {
  const dateTime = coerceDateTime(date, isWallOrTz);
  return toNormalSpaces(dateTime.toLocaleString(DateTime.DATE_HUGE));
}

// returns date in this format: June 15th, 2023 at 4:53 PM
export function dateTimeFormalWithDaySuffix(date: Date | DateTime): string {
  const dateTime = coerceDateTime(date);

  // Luxon doesn't have a function add suffix to the day so it has to be done manually
  const addSuffixToDay = (num: string) => {
    if (Number(num) > 3 && Number(num) < 21) return "th";
    switch (Number(num) % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const formattedDateWithoutDaySuffix = toNormalSpaces(
    dateTime.toFormat("DDD 'at' t")
  );
  const splitDateTime = formattedDateWithoutDaySuffix.split(" ");
  let day = splitDateTime[1].replace(",", "");
  day = `${day}${addSuffixToDay(day)},`;
  // replace day with new day with suffix
  splitDateTime[1] = day;

  return splitDateTime.join(" ");
}

export function dateInFormFormat(date: Date | DateTime | string): string {
  return toNormalSpaces(coerceDateTime(date).toFormat("yyyy-MM-dd"));
}

export function dateTimeMediumOr(
  date: Dateish | null | undefined,
  fallback: string
) {
  if (!date) {
    return fallback;
  }

  return dateTimeMedium(date);
}

// Nothing really to do about this, but both js Date and luxon DateTime are pretty terrible.
// Temporal api can't come soon enough.
export function dateTimeMedium(
  date: Date | DateTime | string,
  locale: string | null = null
): string {
  // On the server, the spaces are narrow-nbsp (8239), on the browser they are normal.
  // No idea how luxon chooses.
  return toNormalSpaces(
    coerceDateTime(date).toLocaleString(DateTime.DATETIME_MED)
  );
}

export function dateTimeMediumWithTimeZone(
  date: DateTime,
  locale: string | null = null
): string {
  // On the server, the spaces are narrow-nbsp (8239), on the browser they are normal.
  // No idea how luxon chooses.
  return toNormalSpaces(
    date.toLocaleString({
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "shortGeneric",
    })
  );
}

// isWall - During coerce, interperete the given date as UTC, and set the locale to UTC.
export function dateFormat(
  date: Date | DateTime | string,
  isWallOrTz: boolean | string = false
): string {
  const coercedDateTime = coerceDateTime(date, isWallOrTz);
  const strVal = coercedDateTime.toLocaleString(DateTime.DATE_MED, {
    // @ts-ignore -- This is just an incorrect type definition from @types/luxon
    zone: isWallOrTz,
  });

  return toNormalSpaces(strVal);
}

export function unixTimestampToDateTime(seconds: number): DateTime {
  return DateTime.fromSeconds(seconds);
}

/**
 * @deprecated
 *
 * This method tries to combine wall time preservation and timezone
 * conversion.  These two concepts should be separated.  Preserving wall time will
 * channging a timezone should be strictly separate from actually just interpreting
 * an instant in a different timezone.  Preserving walltime changes the instant,
 * whereas changing a timezone does not.  This method isn't even consistent
 * in the behavior it tries to use.
 *
 * Instead favor one of:
 *
 * `wallUTCtoWallTZ`  : use wall time from the given instant in UTC, change to a
 *                    new timezone, preserving wall time, and changing the instant
 *
 * `jsDateToDateTime` : preserve the instant, but output a DateTime with a different
 *                    timezone.
 */
export function coerceDateTime(
  date: Date | DateTime | string,
  isWallOrTz: boolean | string = false
): DateTime {
  const toZone: undefined | string =
    typeof isWallOrTz === "boolean"
      ? isWallOrTz
        ? "utc"
        : undefined
      : isWallOrTz;

  return date instanceof Date
    ? DateTime.fromJSDate(date, { zone: toZone })
    : typeof date === "string"
      ? DateTime.fromISO(date)
      : date;
}

// On the server, luxon somehow uses narrow spaces, the spaces are narrow-nbsp (8239), on the browser they are normal.
// No idea how luxon chooses.
function toNormalSpaces(luxonString: string) {
  return luxonString.replace(/\u202f/g, " ");
}

export const operatingNow = () => {
  return DateTime.now() // timestamp in system time
    .setZone(OPERATING_TIMEZONE); // same instant, but in the correct timezone
};

export function nowISOString() {
  return operatingNow().toISO()!;
}

// returns date in this format: YYYY-MM-DD
export function nowISODateString() {
  return operatingNow().toISODate()!;
}

export function nowJSDate() {
  return operatingNow().toJSDate();
}

const dateRegex = /(\d\d\d\d)[\/-](\d\d?)[\/-](\d\d?)/;
export function dateTimeFromDateString(dateString: string) {
  const matches = dateString.match(dateRegex);

  if (matches === null) {
    const e = new Error("Cannot convert datestring to DateTime");
    captureException(e);
    throw e;
  }

  const [_, year, month, day] = matches;

  return DateTime.fromObject(
    {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day),
    },
    {
      zone: OPERATING_TIMEZONE,
    }
  );
}

export const wallUTCtoWallTZDateFormatter = (
  wallTimeUtc: Date | null | undefined
) => {
  if (!wallTimeUtc) {
    return "";
  }

  return dateFormat(wallUTCtoWallTZ(wallTimeUtc));
};

// When dates are deserialized from the DB, they're converted to midnight on the UTC date of
// the date.  We need to convert that "wall time" to the timestamp in our operating TZ.
// the _right_ fix for this is to just stop converting postgres date columns to JS Dates
// and keep them as strings.  This converts a UTC wall time to the same wall time in a
// the target tz.  Changing the instant, but preserving the wall time.
export function wallUTCtoWallTZ(
  wallTime: Date,
  toZone: string = OPERATING_TIMEZONE
): DateTime {
  const utc = DateTime.fromJSDate(wallTime, { zone: "utc" });
  return DateTime.fromObject(utc.toObject(), { zone: toZone });
}

export function wallUTCtoWallTZOrNull(
  wallTime: Date | null | undefined,
  toZone: string = OPERATING_TIMEZONE
): DateTime | null {
  if (wallTime === null || wallTime === undefined) {
    return null;
  }

  return wallUTCtoWallTZ(wallTime, toZone);
}

/**
 * This was probably the right way to do this all along.
 *
 * Given a JS Date object, convert it to a DateTime in a given timezone.
 */
export function jsDateToDateTime<
  D extends Date | null | undefined,
  O = D extends Date
    ? DateTime
    : D extends null
      ? null
      : D extends undefined
        ? undefined
        : never,
>(
  date: D,
  {
    toZone = OPERATING_TIMEZONE,
  }: {
    toZone: string;
  } = {
    toZone: OPERATING_TIMEZONE,
  }
): O {
  if (date === null) {
    return null as O;
  }

  if (date === undefined) {
    return undefined as O;
  }

  return DateTime.fromJSDate(date, { zone: toZone }) as O;
}

export function dateTimeMediumFormatter(
  date: DateTime | null | undefined,
  {
    toZone = OPERATING_TIMEZONE,
  }: {
    toZone: string;
  } = {
    toZone: OPERATING_TIMEZONE,
  }
): string | null | undefined {
  if (!date) {
    return date;
  }

  return dateTimeMedium(date);
}
